import React from "react"

import { Link } from "gatsby";
import moment from "moment";
import "moment/locale/fr";
import { VerticalTimelineElement }  from 'react-vertical-timeline-component';
import EventAvailable from '@material-ui/icons/EventAvailable';

import publicationDateRendererHOC from "./publication-date-rendererHOC"

const EvenementPast = ({ displayData, publicationDate }) => {
	const {
		slug,
		imageHeader,
		titre,
		date,
		description,
	} = displayData;

	const dateStr = moment(new Date(date)).format('LL')
	return (
		<VerticalTimelineElement
			className="vertical-timeline-element--work"
			date={dateStr}
			iconStyle={{
				background: 'white',
				color: '#328925',
				boxShadow: 'rgb(50, 137, 37) 0px 0px 1px 4px, rgba(0, 0, 0, 0.08) 0px 3px 0px inset, rgba(0, 0, 0, 0.05) 0px 3px 0px 4px',
			}}
			icon={<EventAvailable />}
		>
			<div style={{ textAlign: 'center' }} >
				<h3 className="vertical-timeline-element-title">{titre}</h3>
				{imageHeader ? <img style={{ maxWidth: '90%', marginTop: '10px' }} src={imageHeader.localFile.childImageSharp.gatsbyImageData.images.fallback.src} /> : null}
				<p style={{ fontSize: '15px', textAlign: 'left' }}>
					{description.markdownNode.childMarkdownRemark.excerpt}
				</p>
				<div style={{ textAlign: 'center', marginTop: '15px' }}>
					<Link to={"/actualites/" + slug} className="btn btn-primary btn-green btn-sm" style={{ background: '#328925' }}>En savoir plus</Link>
				</div>
			</div>
		</VerticalTimelineElement>
	)
}

export default publicationDateRendererHOC(EvenementPast, 'evenement')

