import React from "react";
import { graphql, Link } from "gatsby";
import moment from "moment"
import "moment/locale/fr"

import { Carousel } from "react-bootstrap" ;
import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css'; // eslint-disable-line

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import EvenementPast from "../../components/evenementPast";


const Agenda = ({ location, data }) => {
	const future = [];
	const past = [];
	const now = new Date();
	data.allGraphCmsEvenement.nodes.forEach((el) => {
		if (now > new Date(el.date)) past.push(el);
		else future.push(el);
	});
	const evenements = past.reverse().map((evenement) => <EvenementPast evenement={evenement} key={evenement.slug} />);
	return (
		<Layout location={location}>
			<SEO title="Agenda" />
			<h2 style={{ textAlign: 'center', paddingTop: '20px', marginBottom: '20px' }}>Événements à venir</h2>
			<Carousel>
				{future.map(({
					slug,
					imageHeader,
					titre,
					description,
					date,
				}) => (
					<Carousel.Item key={slug}>
						{/* <img
							className="d-block w-100"
							src="holder.js/800x400?text=First slide&bg=373940"
							alt="First slide"
						/> */}
						<div style={{ height: '400px', backgroundColor: 'white', textAlign: 'center' }}>
							{imageHeader ? <img style={{ height: '100%' }} src={imageHeader.localFile.childImageSharp.gatsbyImageData.images.fallback.src} /> : null}
						</div>
						<Carousel.Caption>
							<div style={{ backgroundColor: '#f8f8f8', padding: '10px', color: 'black', opacity: 0.85, borderRadius: '10px' }}>
								<h2 style={{ opacity: 1, fontSize: '25px' }}>{titre}</h2>
								<h3 style={{ opacity: 1, fontSize: '21px' }}>{moment(new Date(date)).format('LL')}</h3>
								<p style={{ opacity: 1, fontSize: '15px' }}>{description.markdownNode.childMarkdownRemark.excerpt}</p>
								<div style={{ textAlign: 'center' }}>
									<Link to={"/actualites/" + slug} className="btn btn-primary btn-green btn-sm" style={{ background: '#328925' }}>En savoir plus</Link>
								</div>
							</div>
						</Carousel.Caption>
					</Carousel.Item>
				))}
			</Carousel>
			<h2 style={{ textAlign: 'center', paddingTop: '20px', marginBottom: '20px' }}>Événements passés</h2>
			<VerticalTimeline>
				{evenements}
			</VerticalTimeline>
		</Layout>
	)
}

export default Agenda

export const AgendaQuery = graphql`
query AgendaQuery {
	allGraphCmsEvenement {
		nodes {
			description {
				markdownNode {
					childMarkdownRemark {
						excerpt
					}
				}
			}
			imageHeader {
				localFile {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, height: 400, quality: 100),
					}
				}
			}
			...EvenementData
		}
	}
}
`

